<template>
  <v-container fluid>
    <v-card-title class="headline">Wettkampfstatus ändern</v-card-title>
    <v-card-text>
      <v-row>
        <v-col md="3" cols="12">
          <v-select
            :value="e.status"
            :items="status"
            label="aktueller Wettkampfstatus"
            disabled
          />
        </v-col>
        <v-col md="3" cols="12">
          <v-btn
            block
            :disabled="!(e.status === 'EventScheduled' || e.status === 'EventPostponed' || e.status === 'EventRescheduled')"
            @click="moveonline"
          >
            Wettkampf vorbereiten
          </v-btn>
        </v-col>
        <v-col md="3" cols="12">
          <v-btn
            block
            :disabled="!(e.status === 'EventScheduled' || e.status === 'EventPostponed' || e.status === 'EventRescheduled' || e.status === 'EventMovedOnline') || !readyToStart"
            @click="start"
          >
            Wettkampf starten
          </v-btn>
        </v-col>
        <v-col md="3" cols="12">
          <v-btn
            block
            :disabled="!(e.status === 'EventScheduled' || e.status === 'EventPostponed' || e.status === 'EventRescheduled' || e.status === 'EventMovedOnline' || e.status === 'EventStarted') || !readyToFinish"
            @click="finish"
          >
            Wettkampf abschließen
          </v-btn>
        </v-col>
      </v-row>
      <template v-if="e.status === 'EventMovedOnline' && !readyToStart">
        <h3 style="color:red;margin-bottom:16px;">Um den Wettkampf starten zu können, müssen alle erforderlichen Felder des Wettkampfberichts VOR dem Wettkampf ausgefüllt werden!</h3>
      </template>
      <template v-if="e.status === 'EventStarted' && !readyToFinish">
        <h3 style="color:red;margin-bottom:16px;">Um den Wettkampf abschließen zu können, müssen alle erforderlichen Felder des Wettkampfberichts NACH dem Wettkampf ausgefüllt werden!</h3>
      </template>
      <h3>Erklärung des Status:</h3>
      <ul>
        <li><b>Event leer:</b> Der Wettkampf ist terminiert, es wurden noch keine Ergebnisse eingegeben.</li>
        <li><b>Event verschoben, Termin offen:</b> Der Wettkampf wurde kurzfristig verschoben, ein Ausweichtermin steht noch nicht fest.</li>
        <li><b>Event verschoben, Termin steht fest:</b> Der Wettkampf wurde kurzfristig verschoben, ein Ausweichtermin wurde festgelegt. Es wurden noch keine Ergebnisse eingegeben</li>
        <li><b>Event in Vorbereitung:</b> Turner machen sich gerade warm, es werden die Daten eingegeben. Der Wettkampf wird auf der Startseite angezeigt.</li>
        <li><b>Event begonnen:</b> Wettkampf läuft aktuell, der Wettkampf wird auf der Startseite angezeigt.</li>
        <li><b>Event beendet:</b> Wettkampf wurde beendet. Keine Änderungen mehr durch Vereinsverantwortliche mehr möglich.</li>
        <li><b>Event bestätigt:</b> Ergebnis wurde durch Ligaausschuss bestätigt.</li>
        <li><b>Event abgesagt:</b> Der Wettkampf wurde ersatzlos abgesagt, Entscheidung durch Ligaausschuss fehlt noch.</li>
        <li><b>Event durch Schiedsgericht bewertet:</b> Wettkampf wurde wegen Absage, Startrechtsverletzung o.ä. durch Ligaausschuss bewertet.</li>
      </ul>
    </v-card-text>
  </v-container>
</template>

<script>
import gql from 'graphql-tag'
import { EventStatus } from '@/enum'
import { useGraphQL } from '@/plugins/graphql'
import { useCalc } from '@/views/components/stb_m_2021/plugins/calc'

export default {
  name: 'status',

  setup (props, context) {
    return {
      ...useGraphQL(context),
      ...useCalc(props, context)
    }
  },

  props: {
    id: {
      type: String,
      required: true
    },
    e: {
      type: Object,
      required: true
    },
    r: {
      type: Array,
      required: true
    },
    df: {
      type: Array,
      required: true
    }
  },

  computed: {
    status () {
      return EventStatus
    },
    allteams () {
      return this.e?.teams || []
    },
    readyToStart () {
      return !!this.e.info?.ersthelfer &&
        (!!this.e.info?.unfallhilfe || !!this.e.info?.rettungsdienst)
    },
    dauer () {
      if (!this.e.info?.ende) return 0

      const start = this.e.startDate.match(/.* ([0-9]{2}):([0-9]{2})/)
      const ende = this.e.info.ende.match(/([0-9]{2}):([0-9]{2})/)

      const m = (ende[2] - start[2]) / 60
      const h = ende[1] - start[1] + m

      return h
    },
    readyToFinish () {
      return !!this.e.info?.zuschauer &&
        !!this.e.info?.ende &&
        (this.dauer <= 2.6 || this.e.info?.dauer)
    }
  },

  methods: {
    async moveonline () {
      if (await this.$root.$children[0].$refs.confirm.open('Wirklich in Vorbereitungsmodus setzen?', 'Der Wettkampf kann anschließend nicht mehr zurückgesetzt werden!')) {
        this.updatestatus('EventMovedOnline')
        this.$emit('moveonline')
      }
    },
    async start () {
      if (await this.$root.$children[0].$refs.confirm.open('Wirklich starten?', 'Die Ergebnisse sind dann öffentlich sichtbar!<br><br>Werte auf dem Wettkampfbericht VOR dem Wettkampf können nicht mehr verändert werden.')) {
        this.updatestatus('EventStarted')
        this.$emit('start')
      }
    },
    async finish () {
      if (await this.$root.$children[0].$refs.confirm.open('Wirklich beenden?', 'Es können anschließend keine Änderungen mehr gemacht werden! Das Endergebnis wird hierbei automatisch übertragen und ist dann öffentlich sichtbar.<br><br><b>AUßERDEM KÖNNEN KEINE UNTERSCHRIFTEN MEHR ONLINE GETÄTIGT WERDEN!</b>')) {
        await this.abschliessen()
        this.updatestatus('EventFinished')
        this.$emit('finish')
      }
    },
    updatestatus (status) {
      this.mutate({
        mutation: gql`mutation($id: UUID!, $status: EventStatus!) {
            StbM2021WkUpdateStatus(id: $id, status: $status) { _id }
          }`,
        variables: {
          id: this.id,
          status
        }
      })
    },
    async abschliessen () {
      const isScore = this.mode(this.e) === 'wk_score4'

      for (const t of this.allteams) {
        const team = t.team._id
        const final = this.mannschaftgesamtergebnis(this.e, this.r, t.team._id)
        const score = this.mannschaftgesamtergebnisscore(this.e, this.r, t.team._id)
        const gp = this.mannschaftgesamtergebnisgp(this.e, this.r, this.df, t.team._id)
        const tp = this.mannschaftgesamtergebnistp(this.e, this.r, t.team._id)

        await this.mutate({
          mutation: gql`
            mutation($id: UUID!, $team: UUID!,
              $final: Float, $score: Int, $gp: Float, $tp: Float
              ) {
                StbM2021WkTeamResultAdd(
                  event: $id, team: $team,
                  final: $final, score: $score, gp: $gp, tp: $tp
                ) { _id }
            }
          `,
          variables: {
            id: this.id,
            team,
            final,
            score: isScore ? score : null,
            gp,
            tp
          }
        })
      }
    }
  }
}
</script>

<style scoped>

</style>
